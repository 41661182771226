.animation-rotate{
    animation-name: rotate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
