.animation-appear{
    opacity: 0;
    animation-name: appear;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes appear {
    0% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}
