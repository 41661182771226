.language-picker-container {
    width: 100%;
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    gap: 5px;
    
    color: var(--color-text-dark-hex);
    font-weight: 600;
    font-size: var(--font-size-large);
}
.language-picker-icon{
    height: 30px;
    width: auto;
    fill: var(--color-text-dark-hex);
    transition: all 0.5s;
}
.language-picker-icon:hover {
    transform: rotate(360deg) scale(1.2);
}
.language-picker-option,
.language-picker-option-selected {
    transition: all 0.5s;
    cursor: pointer;
}
.language-picker-option:hover {
    transform: scale(1.2);
}
.language-picker-option-selected {
    text-decoration: underline 2px;
}