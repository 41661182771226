.error-page-container, 
.error-page-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-page-container {
  padding: 64px 32px;
  gap: 32px;
}
.error-page-container > div {
  gap: 4px;
}
.error-page-container img,
.error-page-container h1,
.error-page-container p{
  margin: 0;
  padding: 0;
}
.error-page-container img{
  width: 100px;
  height: auto;
}
.error-page-container h1{
  font-size: var(--font-size-xxx-large);
}
.error-page-container p{
  font-size: var(--font-size-large);
  text-align: center;
}
.error-page-container a{
  color: var(--color-text-blue-hex);
  text-decoration: underline 1px var(--color-text-blue-hex);
}