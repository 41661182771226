.animation-pulse{
    box-shadow: 0px 0px 1px 1px var(--color-logo-blue-hex);
    border-radius: 15px;
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0px 0px 1px 1px rgb(var(--color-logo-blue-rgb-r), var(--color-logo-blue-rgb-g), var(--color-logo-blue-rgb-b), 0.4);
    }
    30% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}
